import { useContext } from "react";
import { SessionContext, SessionContextState } from "context/SupabaseSessionContext";

const useSessionState = () => {
  const sessionState = useContext<SessionContextState | null>(SessionContext);

  if (typeof sessionState === "undefined" || typeof sessionState !== "object") {
    return {
      session: null,
      user: null,
      isSessionLoading: false,
      currentUser: undefined,
      updateNewUserPhone: () => {},
      peopleDataFetched: false
    };
  }

  return sessionState as SessionContextState;
};

export type SessionContextType = ReturnType<typeof useSessionState>;

export default useSessionState;
