import { useQuery } from "@tanstack/react-query";

import useSupabaseBrowser from "utils/supabaseBrowserClient";
import { getAllWorkspaces } from "lib/adminApi";
import { USER_TYPE } from "utils/constants";

const useWorkspaces = (userType?: USER_TYPE) => {
  const supabaseClient = useSupabaseBrowser();
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["workspaces", userType],
    queryFn: () => getAllWorkspaces(supabaseClient),
    enabled: !!userType,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchInterval: false,
    refetchIntervalInBackground: false
  });

  return {
    data: data?.data,
    isLoading,
    refetch
  };
};

export default useWorkspaces;
