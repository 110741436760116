"use client";

import { useEffect, useRef } from "react";
import { useJitsu } from "@jitsu/jitsu-react";
import startCase from "lodash/startCase";
import { usePathname, useSelectedLayoutSegments } from "next/navigation";

import useCurrentUser from "hooks/useCurrentUser";
import useInLayout from "hooks/useInLayout";
import { useAnalytics } from "context/AnalyticsContext";

export const UserPageViewEventTrack = () => {
  const user = useCurrentUser();
  const selectedLayoutSegments = useSelectedLayoutSegments();
  const pathName = usePathname();
  const paths = pathName?.split("/");
  const prevPathname = useRef("");
  const { isTable } = useInLayout();
  const segment = isTable ? selectedLayoutSegments?.[2] : selectedLayoutSegments?.[1] || paths?.[1]; // for page name
  const { analytics: jitsuAnalytics } = useJitsu();
  const { geoLocationInfo, geoLocationInfoLoaded } = useAnalytics();

  useEffect(() => {
    if (prevPathname.current === pathName || !geoLocationInfoLoaded || !user) return;
    if (segment) {
      const userTraits = { email: user.email, name: user.full_name, phone: user.phone, role: user.type };
      jitsuAnalytics.page(startCase(segment), {
        userId: user.user_id,
        userTraits,
        context: {
          location: geoLocationInfo?.location,
          ip: geoLocationInfo?.ip
        }
      });
      prevPathname.current = pathName || "";
    }
  }, [user, segment, geoLocationInfo, geoLocationInfoLoaded, jitsuAnalytics, pathName]);

  return null;
};
